import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Radio from '@material-ui/core/Radio';
import { ToastContainer } from 'react-toastify';
import validator from 'validator';
import _ from 'lodash';
import moment from 'moment';
import countryList from 'country-list';
import useFormStyle from '../styles';
import useStyle from '../../../widgets/mui-text-field/styles';
import Alert from '../../../svgs/alert';
import Dialog from '../../../widgets/dialog';
import Progress from '../../../widgets/progress';
import Tooltip from '../../../widgets/tooltip';
import { MuiTextField } from '../../../widgets/mui-text-field/TextField';
import { MuiSelectSingleChoice } from '../../../widgets/mui-text-field/SingleChoiceSelect';
import { IValueLabelPair } from '../../../../models/Common';
import Toast from '../../../../services/notifications';
import {
    createAccount,
    getNextAvailableDomain,
    isLoading,
    selectAccounts,
    updateAccount,
    updateNetwork,
    upsertLicense,
    updateIntegrationsLicense,
    updateRtiSettings,
    getLicense,
} from '../../../../redux/slicers/accounts';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { IFormattedAccount } from 'models/Account';
import { NEW_PRODUCT_NAMES, Products } from '../../../../services/api/consts';

interface IAccountForm {
    action: string;
}

interface AccountParams {
    tab: string;
    accountId: string;
}

export const DATATYPE = {
    SERVER: 'server',
    CLIENT: 'client',
};

export const AccountForm = ({ action }: IAccountForm) => {
    const filteredAccountTypes = ['Cancelled', 'Paid poc', 'Demo_For_Sales'];
    const createNewAccount = action === 'create';
    const params: AccountParams = useParams();
    const accountId = parseInt(params.accountId);
    const currentAccount = useAppSelector((state) => {
        return state.accounts.accounts?.find((a: { id: number }) => a.id === accountId);
    });
    const { networks, ...accountData } = currentAccount ? currentAccount : { networks: undefined };
    const [account, setAccount] = useState<Partial<IFormattedAccount>>(createNewAccount ? {} : accountData);
    const [productValue, setProductValue] = useState<string[]>((account?.products && account?.products.map((prod: IValueLabelPair) => prod.label)) || []);
    const [dataTypes, setDataTypes] = useState<string[]>([]);

    if (_.isEmpty(account) && !_.isEmpty(accountData)) {
        setAccount(accountData);
        /* @ts-ignore */
        setProductValue(accountData.products.map((prod: { label: any }) => prod.label));
    }
    const classes = useStyle();
    const formClasses = useFormStyle();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { products = [], regions = [], accountTypes = [], bundlePlans = [], mediaSpendTiers = [], ses = [], csms = [], dataLocations = [] } = useSelector(selectAccounts);
    const productOptions: any[] = products.map(mapIds((str) => str.charAt(0).toUpperCase() + str.substr(1)));
    const planOptions = bundlePlans.map(mapIds(_.capitalize));
    const [changeDomainConfirmOpen, setChangeDomainConfirmOpen] = useState(false);
    const rtiSettings = useAppSelector((state) => state.accounts.rtiSettings);

    const getListOptions = (list: any[]) => {
        const activeAndFormattedUsersOptions: any[] = list.reduce((options: any[], { id, name, email }: { id: string; name: string; email: string }) => {
            if (id.startsWith('auth0|')) {
                options = [
                    ...options,
                    {
                        value: id,
                        label: (
                            <span>
                                <small>{name} </small>
                                <small>{email}</small>
                            </span>
                        ),
                        name: name,
                    },
                ];
            }
            return options;
        }, []);

        return activeAndFormattedUsersOptions.sort((a: { value: string; label: HTMLElement; name: string }, b: { value: string; label: HTMLElement; name: string }) => {
            if (a.name < b.name) {
                return -1;
            }
            return 1;
        });
    };

    const cmsOptions = getListOptions(csms);
    const seOptions = getListOptions(ses);

    const accountTypesFilter = (name: string) => {
        return !filteredAccountTypes.includes(name);
    };

    const sortAccountTypes = (a: any, b: any) => {
        return a.value === 4 ? 10 : b.value === 4 ? -10 : a.value - b.value;
    };

    const mediaSpendTiersOptions = mediaSpendTiers.map(({ id, name }) => ({ value: id, label: name }));
    const regionOptions = regions.map(mapIds((str) => str.toUpperCase()));
    const dataLocationOptions = dataLocations.map(mapIds((str) => str));
    const accountTypesOptions: any[] = accountTypes
        .filter(({ name }: { name: string }) => (!createNewAccount ? true : accountTypesFilter(name)))
        .map(mapIds(_.capitalize))
        .sort(function (a, b) {
            return sortAccountTypes(a, b);
        });
    const countryOptions = Object.entries(countryList.getCodeList())
        .map(([value, label]) => ({ value, label }))
        .sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            return 1;
        });
    const isAssociatedNetworkDirty = useRef(false);

    const onBack = () => {
        history.push('/');
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isAccountDirty = Object.entries(account).some(([key, value]) => accountData[key] !== value);
        const errors = [...isValidState(account), ...(createNewAccount ? [] : isAccountDirty || isAssociatedNetworkDirty ? [] : ['Nothing changed'])];
        if (errors.length) {
            return Toast({ type: 'error', message: errors.shift() });
        }
        const network = createNewAccount || account.isAgency ? undefined : networks && networks[0];
        const networkDiff = network ? { id: network.id, account_id: account.id, whiteLabelDomain: network.whiteLabelDomain } : undefined;
        const accountDiff = createNewAccount ? account : getEditAccountBody(account, accountData);

        const handleUpdateRtiSettings = (accountData: Partial<IFormattedAccount>, dataTypes: string[]) => {
            // Checking for OSS/Defend product in both account creation and editing scenarios
            // For account creation, products have format: { id: number }
            // For account editing, products have format: { value: number, label: string }
            if (
                accountData?.products?.some(
                    (product: { label?: string; id?: number; value?: number }) =>
                        product.label === NEW_PRODUCT_NAMES[Products.OSS] || // Account editing case - checks label "CHEQ Defend"
                        product.id === Products.OSS || // Account creation case - checks id: 2
                        product.value === Products.OSS // Account editing case - checks value: 2
                ) &&
                dataTypes
            ) {
                const transformedParams = {
                    isApiIntegration: accountData?.defendDataTypes?.includes(DATATYPE.SERVER),
                    isClientSideIntegration: accountData?.defendDataTypes?.includes(DATATYPE.CLIENT),
                    networkId: accountData.networkId || accountData.network_id,
                    status: true,
                };

                    // @ts-ignore
                    dispatch(updateRtiSettings(transformedParams));
            }
        };

        if (createNewAccount || isAccountDirty) {
            if (createNewAccount) {
                try {
                    // @ts-ignore
                    const accountCreated = await dispatch(createAccount(account));
                   // @ts-ignore
                    const createdLicenseWithProducts = await dispatch(getLicense({ id: accountCreated.payload.networkId }));
                    if (createdLicenseWithProducts) {
                            handleUpdateRtiSettings(account, dataTypes);
                    }
                } catch (error) {
                    console.error('Error creating account:', error);
                }
            } else {
                // @ts-ignore
                dispatch(updateAccount(accountDiff));
                if (accountDiff.products) {
                    // @ts-ignore
                    dispatch(upsertLicense({ network_id: network.id, products: accountDiff.products, accountId: account.id }));
                    // @ts-ignore
                    dispatch(updateIntegrationsLicense({ networkId: network.id, products: accountDiff.products }));
                }
                handleUpdateRtiSettings(accountDiff, dataTypes);
            }
        }
        if (networkDiff && isAssociatedNetworkDirty.current) {
            // @ts-ignore
            dispatch(updateNetwork(networkDiff));
        }
        onBack();
    };

    const onGetNextAvailableDomain = (accountId: number, networkId: number | undefined) => {
        // @ts-ignore
        dispatch(getNextAvailableDomain({ accountId, networkId }));
        isAssociatedNetworkDirty.current = true;
    };

    const renderAccountTitle = () => {
        if (createNewAccount) {
            return (
                <p>
                    <b>CREATE</b> NEW ACCOUNT
                </p>
            );
        }
        return (
            <p>
                <b>EDIT</b> EXISTING ACCOUNT
            </p>
        );
    };

    if (!createNewAccount && !accountData) {
        onBack();
        return <></>;
    }

    // Declaring the DESIGN PROPS of the input components of the form

    const inputProps = { className: classes.smallInputMui };
    const inputSelectProps = { className: classes.smallSelectMui };
    const InputProps = {
        classes: {
            root: classes.accountForm,
            focused: classes.accountFormFocused,
            notchedOutline: classes.accountFormNotchedOutline,
        },
    };
    const InputLabelProps = {
        classes: {
            root: classes.accountFormLabel,
            focused: classes.accountFormLabelFocused,
        },
    };
    const SelectProps = {
        MenuProps: {
            classes: {
                paper: classes.accountFormSelectPaperLong,
            },
        },
    };
    const shortSelectProps = {
        MenuProps: {
            classes: {
                paper: classes.accountFormSelectPaperShort,
            },
        },
    };

    const dataTypeOptions = [
        { value: DATATYPE.SERVER, label: DATATYPE.SERVER },
        { value: DATATYPE.CLIENT, label: DATATYPE.CLIENT },
    ];

    useEffect(() => {
        if (rtiSettings && Object.keys(rtiSettings).length > 0) {
            const initialDataTypes: string[] = [];

            if (rtiSettings.isApiIntegration) {
                initialDataTypes.push(DATATYPE.SERVER);
            }
            if (rtiSettings.isClientSideIntegration) {
                initialDataTypes.push(DATATYPE.CLIENT);
            }

            setDataTypes(initialDataTypes);
            setAccount((prevAccount) => ({
                ...prevAccount,
                defendDataTypes: initialDataTypes,
            }));
        }
    }, [rtiSettings]);

    const dataTypeValue = dataTypes;

    const handleChange = (event: SelectChangeEvent<string | string[]>) => {
        const { value } = event.target;

        let newDataTypes: string[] = [];

        if (Array.isArray(value)) {
            newDataTypes = value.filter((item) => item === DATATYPE.SERVER || item === DATATYPE.CLIENT);
        } else {
            if (value === DATATYPE.SERVER || value === DATATYPE.CLIENT) {
                newDataTypes = [value];
            }
        }

        setDataTypes(newDataTypes);
        setAccount((prevAccount) => ({
            ...prevAccount,
            defendDataTypes: newDataTypes,
        }));
    };
    return (
        <div className="container">
            <form className={formClasses.form} onSubmit={onSubmit}>
                <div className="form-title">{renderAccountTitle()}</div>
                <div>
                    <p className="cq-heading">
                        <b>CONTACT</b> INFO
                    </p>
                    <div className="input-group">
                        <MuiTextField
                            id="accountName"
                            label="Account Name"
                            size="small"
                            onChange={(event) => setAccount((s: any) => ({ ...s, name: event.target.value }))}
                            value={account?.name || ''}
                            inputProps={inputProps}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                        />
                        <MuiTextField
                            id="accountEmail"
                            label="Account Email"
                            size="small"
                            type="email"
                            onChange={(event) => setAccount((s: any) => ({ ...s, email: event.target.value }))}
                            value={account?.email || ''}
                            inputProps={inputProps}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                        />
                    </div>
                    <div className="input-group">
                        <MuiTextField
                            id="billingAddress"
                            label="Billing Address"
                            size="small"
                            fullWidth={true}
                            onChange={(event) =>
                                !isSpecialCharacters(event.target.value) && event.target.value.length < 50 && setAccount((s: any) => ({ ...s, billingAddress: event.target.value }))
                            }
                            value={account?.billingAddress || ''}
                            inputProps={inputProps}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                        />
                    </div>
                    <div className="input-group">
                        <MuiTextField
                            id="city"
                            label="City"
                            size="small"
                            onChange={(event) =>
                                !isSpecialCharacters(event.target.value) &&
                                !`${event.target.value}`.match(/^\d+$/) &&
                                event.target.value.length < 20 &&
                                setAccount((s: any) => ({ ...s, city: event.target.value }))
                            }
                            value={account?.city || ''}
                            inputProps={inputProps}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                        />
                        <MuiSelectSingleChoice
                            id="country"
                            label="Country"
                            size="small"
                            onChange={(event) =>
                                !isSpecialCharacters(event.target.value) &&
                                !`${event.target.value}`.match(/^\d+$/) &&
                                event.target.value.length < 20 &&
                                setAccount((s: any) => ({ ...s, country: event.target.value }))
                            }
                            value={account?.country || ''}
                            options={countryOptions}
                            inputProps={inputSelectProps}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                            SelectProps={SelectProps}
                        />
                    </div>
                    <div className="input-group">
                        <MuiTextField
                            id="phoneNumber"
                            label="Phone Number"
                            size="small"
                            type="number"
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                return setAccount((s) => ({ ...s, phone: value }));
                            }}
                            value={account?.phone || ''}
                            inputProps={inputProps}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                        />
                        <MuiTextField
                            id="zipCode"
                            label="Zip Code"
                            size="small"
                            type="number"
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                return value.length < 15 && (validator.isAlphanumeric(value) || !value) && setAccount((s) => ({ ...s, zipCode: value }));
                            }}
                            value={account?.zipCode || ''}
                            inputProps={inputProps}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                        />
                    </div>
                    <div className="input-group">
                        <MuiTextField
                            id="domain"
                            label="Domain"
                            size="small"
                            placeholder="Customer Domain (https://customer-site.com)"
                            fullWidth
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                value.length < 2048 && setAccount((s) => ({ ...s, domain: value }));
                            }}
                            value={account?.domain || ''}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                        />
                    </div>
                    <div className="input-group">
                        <MuiTextField
                            id="customerLogo"
                            label="Customer Logo"
                            size="small"
                            placeholder="Customer Logo (https://image-url.com)"
                            fullWidth={true}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                value.length < 2048 && setAccount((s) => ({ ...s, logoUrl: value }));
                            }}
                            value={account?.logoUrl || ''}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                        />
                    </div>
                </div>
                <div>
                    <p className="cq-heading">
                        <b>PLAN</b> INFO
                    </p>
                    <AccountType accountType={account?.accountType} types={accountTypesOptions} onChange={(accountType) => setAccount((s: any) => ({ ...s, accountType }))} />
                    <div className="input-group">
                        <FormControl size="small">
                            <InputLabel id="demo-multiple-checkbox-label">Product</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={productValue || ''}
                                onChange={(event: SelectChangeEvent<typeof productValue>) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setProductValue(typeof value === 'string' ? value.split(',') : value);
                                    setAccount((prevState: any) => ({
                                        ...prevState,
                                        products:
                                            (Array.isArray(value) &&
                                                value.map((product: string) => productOptions.find((productOption: IValueLabelPair) => productOption.label === product))) ||
                                            [],
                                        productId: 1,
                                    }));
                                }}
                                input={<OutlinedInput label="Product" size="small" />}
                                renderValue={(selected) => selected.join(', ')}
                                inputProps={{ className: classes.smallSelectMui }}
                                className={classes.accountForm}
                            >
                                {productOptions.map((p: IValueLabelPair) => (
                                    <MenuItem key={p.value} value={p.label}>
                                        <Checkbox checked={productValue.indexOf(p.label) > -1} />
                                        <ListItemText primary={p.label} sx={{ textOverflow: 'ellipsis' }} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <MuiSelectSingleChoice
                            id="customerPlan"
                            label="Customer Plan"
                            size="small"
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                return setAccount((s) => ({ ...s, bundlePlan: value }));
                            }}
                            value={account?.bundlePlan || ''}
                            options={planOptions}
                            inputProps={inputSelectProps}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                            SelectProps={SelectProps}
                        />
                        <Tooltip
                            /* @ts-ignore */
                            title={bundlePlans.reduce(
                                (comp: any, { name, brands, users, admin, manager }: any, idx: any) => [
                                    ...comp,
                                    <div key={idx} style={{ whiteSpace: 'nowrap' }}>
                                        {_.capitalize(name)}: Networks - {brands}, Admin - {admin}, Managers - {manager}, users - {users}
                                    </div>,
                                ],
                                []
                            )}
                            disable={false}
                        >
                            <InfoOutlinedIcon style={{ position: 'absolute', marginLeft: 507, marginTop: 8, cursor: 'pointer' }} />
                        </Tooltip>
                    </div>
                    <div className="input-group">
                        <MuiSelectSingleChoice
                            id="mediaSpend"
                            label="Media Spend Tiers"
                            size="small"
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                return setAccount((s) => ({ ...s, mediaSpendTierId: value }));
                            }}
                            value={account?.mediaSpendTierId || ''}
                            options={mediaSpendTiersOptions}
                            inputProps={inputSelectProps}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                            SelectProps={SelectProps}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="End Date"
                                inputFormat="yyyy/MM/dd"
                                value={account?.endDate || new Date()}
                                onChange={(value) =>
                                    /* @ts-ignore */
                                    setAccount((s: any) => ({ ...s, endDate: value && !Number.isNaN(new Date(value).getTime()) ? value.toISOString().split('T')[0] : '' }))
                                }
                                renderInput={(params) => <TextField {...params} size="small" InputLabelProps={InputLabelProps} />}
                                InputProps={{
                                    classes: {
                                        root: classes.accountForm,
                                        focused: classes.accountFormFocused,
                                        notchedOutline: classes.accountFormNotchedOutline,
                                    },
                                    className: classes.smallDateMui,
                                }}
                                OpenPickerButtonProps={{ classes: { root: classes.dateIcon } }}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="input-group">
                        <MuiSelectSingleChoice
                            id="se"
                            label="SE"
                            size="small"
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                return setAccount((s) => ({ ...s, seAuth0Id: value }));
                            }}
                            value={account?.seAuth0Id || ''}
                            options={seOptions}
                            inputProps={inputSelectProps}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                            SelectProps={shortSelectProps}
                        />
                    </div>
                    <div className="input-group">
                        <MuiSelectSingleChoice
                            id="csm"
                            label="CSM"
                            size="small"
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                return setAccount((s) => ({ ...s, csmAuth0Id: value }));
                            }}
                            value={account?.csmAuth0Id || ''}
                            options={cmsOptions}
                            inputProps={inputSelectProps}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                            SelectProps={shortSelectProps}
                        />
                        <MuiSelectSingleChoice
                            id="region"
                            label="Region"
                            size="small"
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                return setAccount((s) => ({ ...s, regionId: value }));
                            }}
                            value={account?.regionId || ''}
                            options={regionOptions}
                            inputProps={inputSelectProps}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                            SelectProps={shortSelectProps}
                        />
                    </div>
                    <div className="input-group">
                        <MuiSelectSingleChoice
                            id="dataLocationId"
                            label="Data Location"
                            size="small"
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                return setAccount((s) => ({ ...s, dataLocationId: value }));
                            }}
                            value={account?.dataLocationId || ''}
                            options={dataLocationOptions}
                            inputProps={inputSelectProps}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                            SelectProps={SelectProps}
                            disabled={createNewAccount ? false : true}
                        />
                    </div>
                    {productValue.includes(NEW_PRODUCT_NAMES[Products.OSS]) && (
                        <div className="input-group">
                            <FormControl size="small">
                                <InputLabel id="demo-multiple-checkbox-label">Defend Data Type</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={dataTypes}
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Defend Data Type" size="small" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    inputProps={{ className: classes.smallSelectMui }}
                                    className={classes.accountForm}
                                >
                                    {dataTypeOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            <Checkbox checked={dataTypeValue.indexOf(option.value) > -1} />
                                            <ListItemText primary={option.label} sx={{ textOverflow: 'ellipsis' }} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    <div className={formClasses.attention}>
                        <Alert style={{ marginRight: 5, marginTop: 5 }} />
                        <div className="notice text-muted" style={{ fontSize: 14 }}>
                            Region configuration changes the dashboard stats timezone
                        </div>
                    </div>
                </div>
                <CheqInfo
                    account={account}
                    networks={networks}
                    onChangeDomainClick={() => setChangeDomainConfirmOpen(true)}
                    classes={classes}
                    loading={dispatch(isLoading('getNextAvailableDomain')) || dispatch(isLoading('getAssociatedNetworks'))}
                />
                <div className="form-actions">
                    <button className="cq-btn-lg" type="submit">
                        {createNewAccount ? 'CREATE' : 'SAVE'}
                    </button>
                </div>
            </form>
            <ToastContainer />
            <Dialog
                onClose={() => setChangeDomainConfirmOpen(false)}
                isOpen={changeDomainConfirmOpen}
                onSubmit={() => {
                    onGetNextAvailableDomain(accountId, networks && networks[0].id);
                }}
                title="Change Serving Domain"
                body="Are you sure you want to change the serving domain?"
                Component={false}
                props={{}}
            />
        </div>
    );
};

const getEditAccountBody = (currentState: Partial<IFormattedAccount>, account: Partial<IFormattedAccount>) => {
    const reqBody: any = { ...currentState };

    if (account.name !== currentState.name || account.logoUrl !== currentState.logoUrl) {
        reqBody.updateOrgData = true;
    }

    if (account.csmAuth0Id !== currentState.csmAuth0Id || account.seAuth0Id !== currentState.seAuth0Id) {
        reqBody.updateOrgMembers = true;
    }

    if (account.regionId !== currentState.regionId || account.email !== currentState.email) {
        reqBody.updateOrgMetadata = true;
    }

    reqBody.updateProducts = account.products !== currentState.products;
    return reqBody;
};

export const mapIds =
    (formater = (str: string) => str): any =>
    ({ name, id }: { name: string; id: string }) => ({ value: id, label: formater(name.replace(/_/g, ' ')) });

const isValidState = (state: any) => {
    const minDate = moment().add(1, 'days');
    const maxDate = moment().add(10, 'years');
    const hasCheqDefend = state.products?.some((product: { value: number; label: string }) => product.label === NEW_PRODUCT_NAMES[Products.OSS]);
    const errors = [];
    if (!state.accountType) errors.push(`Account type is required`);
    if (!state.name) {
        errors.push(`Account name is required`);
    } else if (state.name.length < 3 || isSpecialCharacters(state.name)) {
        errors.push(`Invalid account name`);
    }
    if (!state.mediaSpendTierId && !state.isAgency) errors.push(`Invalid Budget`);
    if (!state.products || _.isEmpty(state.products)) errors.push(`Product is required`);
    if (!state.productId) errors.push(`Product is required`);
    if (!state.email || !validator.isEmail(state.email)) errors.push(`Invalid email`);
    if (!state.bundlePlan) errors.push(`Invalid plan`);
    if (!state.seAuth0Id) errors.push(`Invalid se`);
    if (!state.csmAuth0Id) errors.push(`Invalid csm`);
    if (!state.dataLocationId) errors.push(`Data location is required`);
    if (!state.endDate || !moment(state.endDate, 'YYYY-MM-DD', true).isValid()) errors.push(`Invalid end date POC`);
    if (state.endDate && moment(state.endDate, 'YYYY-MM-DD').isBefore(minDate)) errors.push(`Invalid end, date must be after ${moment().format('YYYY-MM-DD')}`);
    if (state.endDate && moment(state.endDate, 'YYYY-MM-DD').isAfter(maxDate)) errors.push(`Invalid end, date must be before ${moment().format('YYYY-MM-DD')}`);
    if (state.domain && !validator.isURL(state.domain, { require_protocol: true, require_valid_protocol: true })) errors.push(`Invalid account URL`);
    if (state.logoUrl && !validator.isURL(state.logoUrl, { require_protocol: true, require_valid_protocol: true })) errors.push(`Invalid account image URL`);
    if (!state.regionId) errors.push(`Region is required`);
    if (hasCheqDefend && (!state.defendDataTypes || state.defendDataTypes.length === 0)) {
        errors.push('Data types are required for CHEQ Defend');
    }
    return errors;
};

const AccountType = ({ types = [], onChange, accountType }: { types: IValueLabelPair[]; onChange: (value: string) => void; accountType: any }) => {
    const formClasses = useFormStyle();
    return (
        <div style={{ display: '-webkit-box' }}>
            <div className="notice" style={{ fontSize: 18 }}>
                Select the account type:{' '}
            </div>
            {types.map(({ value, label }: any, key: any) => (
                // TODO - need to fix any
                <div key={key} className={formClasses.accountTypeContainer}>
                    <Radio
                        className={formClasses.accountTypeRadio}
                        checked={accountType === value}
                        onChange={() => {
                            onChange(value);
                        }}
                    />
                    <div className={formClasses.title} style={{ fontSize: 14 }}>
                        {label}
                    </div>
                </div>
            ))}
        </div>
    );
};

const CheqInfo = ({ account, networks, onChangeDomainClick, classes, loading }: any) => {
    if (account.isAgency || !Array.isArray(networks) || !networks[0]) {
        return <></>;
    }
    const { whiteLabelDomain } = networks[0];
    const servingDomain = whiteLabelDomain?.domain;

    return (
        <div className="cheq-info-block">
            <p className="cq-heading">
                <b>CHEQ</b> INFO
            </p>
            <div>
                {loading ? (
                    <div className={`field-group ${classes.largeInput}`} style={{ height: '57px' }}>
                        <Progress />
                    </div>
                ) : (
                    <>
                        <div className={`field-group ${classes.largeInput}`}>
                            <div className="field-key">Serving Domain</div>
                            <div className="field-value">{servingDomain}</div>
                        </div>
                        {whiteLabelDomain?.tainted ? (
                            <div className={classes.attention}>
                                <Alert style={{ marginRight: 5, marginTop: 5 }} />
                                <div className="notice text-muted" style={{ fontSize: 14 }}>
                                    This domain is blocked by Add-Blockers
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                )}
                {whiteLabelDomain?.tainted ? (
                    <div className={`field-group ${classes.largeInput}`}>
                        <button
                            className="cq-btn secondary"
                            onClick={(e) => {
                                e.preventDefault();
                                if (loading) return;
                                onChangeDomainClick();
                            }}
                        >
                            Change Domain
                        </button>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

const isSpecialCharacters = (str = '') => '?,:;{[}]\\|*/+=)(^%$#!`~@&_><'.split('').some((char) => str.includes(char));
