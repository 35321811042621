export enum Products {
    CAS = 1,
    OSS = 2,
    MIS = 3,
    SLP = 8,
    PRIVACY = 5,
}

const CHEQ_ACQUISITION ='CHEQ Acquisition'
const CHEQ_DEFEND = 'CHEQ Defend';
const CHEQ_ANALYTICS = 'CHEQ Analytics';
const CHEQ_FORM_GUARD = 'CHEQ Form Guard';
const CHEQ_PRIVACY = 'Privacy';

export const NEW_PRODUCT_NAMES = {
    [Products.CAS]: CHEQ_ACQUISITION,
    [Products.OSS]: CHEQ_DEFEND,
    [Products.MIS]: CHEQ_ANALYTICS,
    [Products.SLP]: CHEQ_FORM_GUARD,
    [Products.PRIVACY]: CHEQ_PRIVACY
};
