import { PLATFORM_EVENT_TRIGGER_SELECT_ITEMS } from './../../../../../constants/complianceRules';
import { PLATFORM_EVENT_TRIGGER_OPTIONS, PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES } from '../../../../../constants/complianceRules';
import { SelectItem } from 'types';
import { PlatformEventTriggerOptionValues } from 'types';
import { useMemo } from 'react';
import { useTagData } from 'services/hooks';

export const usePlatformEventsTriggerOptions = (network: number) => {
    const { tags, isLoading } = useTagData(network);
    const tagsWithConsentSettings = useMemo(() => {
        let result: Record<number, { label: string; selected: SelectItem<PlatformEventTriggerOptionValues> }> = {};
        if (Array.isArray(tags)) {
            result = tags.reduce<typeof result>((accumulator, current) => {
                let selected: SelectItem<PlatformEventTriggerOptionValues>;
                if (current.complianceRules?.consent) {
                    selected = PLATFORM_EVENT_TRIGGER_SELECT_ITEMS[PLATFORM_EVENT_TRIGGER_OPTIONS[PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.NONE]];
                } else if (current.complianceRules?.threatGroup) {
                    selected = PLATFORM_EVENT_TRIGGER_SELECT_ITEMS[PLATFORM_EVENT_TRIGGER_OPTIONS[PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.MALICIOUS]];
                } else {
                    selected = PLATFORM_EVENT_TRIGGER_SELECT_ITEMS[PLATFORM_EVENT_TRIGGER_OPTIONS[PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.ALL]];
                }
                accumulator[current.searchId] = { label: current.value, selected };
                return accumulator;
            }, result);
        }
        return result;
    }, [tags]);

    return {
        isLoading,
        triggerOptions: PLATFORM_EVENT_TRIGGER_SELECT_ITEMS,
        tagsWithConsentSettings,
        tagsAmount: tags?.length || 0,
    };
};
