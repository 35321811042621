import { AccountsService } from '../../services/api/accounts.service';
import { UpsertComplianceRulesConfig } from 'types';
import { useCallback, useState } from 'react';

type UpdateComplianceRulesState = {
    isLoading: boolean;
    error?: unknown;
};

export const useUpdateComplianceRules = (networkId: number) => {
    const [state, setState] = useState<UpdateComplianceRulesState>({ isLoading: false });
    const mutate = useCallback(
        async (params: UpsertComplianceRulesConfig) => {
            try {
                setState({ isLoading: true });
                await AccountsService.upsertComplianceRules(networkId, params);
                setState({ isLoading: false });
            } catch (error) {
                setState({ isLoading: false, error });
            }
        },
        [networkId]
    );
    return { ...state, mutate };
};
