import { AccountsService } from 'services/api/accounts.service';
import { useEffect, useState } from 'react';
import { Tag } from 'types';

export type TagDataState = {
    tags?: Array<Tag>;
    isLoading: boolean;
};

export const useTagData = (network: number) => {
    const [state, setState] = useState<TagDataState>({ tags: [], isLoading: true });

    useEffect(() => {
        (async () => {
            try {
                setState({ tags: [], isLoading: true });
                const tags = await AccountsService.getAllTags(network);
                setState({ tags, isLoading: false });
            } catch (error) {
                if (error instanceof Error && error.name != 'AbortError') setState({ isLoading: false });
                console.error(error);
            }
        })();
        return () => AccountsService.abort();
    }, [network]);
    return state;
};
